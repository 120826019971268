@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
html {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

body {
  background: #fff;
  color: #000;
  font-family: sans-serif;
  font-size: 15px;
  max-width: 600px;
}

.container-content {
  width: 100vw;
  max-width: 600px;
  padding: 135px 3vw 0px 3vw;
  background-color: #fff;
}

.logged-container-content {
  width: 100vw;
  max-width: 600px;
  padding: 135px 3vw 60px 3vw;
  background-color: #fff;
}

a {
  color: #2c357c;
  text-decoration: none;
}
a:hover, a:active {
  color: #ee3842;
}

.btn-purple {
  background-color: #2c357c;
  color: #fff;
}
.btn-purple:hover, .btn-purple:active {
  background-color: #ee3842;
  color: #fff;
}

#welcomeUser {
  font-size: 22px;
}

.text {
  font-weight: bolder;
}
.text-purple {
  color: #2c357c;
}
.text-red {
  color: #ee3842;
}

.ErrorText,
.input-error {
  color: #f00;
  font-size: 12px;
  font-style: italic;
}

.required-icon {
  margin: 0 2px;
  color: #f00;
}

#resendBtn {
  border: none;
  background-color: transparent;
  color: #2c357c;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.qr-block {
  max-height: calc(100vh - 195px);
}

header {
  height: 120px;
  border-bottom: 1px solid #2c357c;
  background-color: #fff !important;
  opacity: 1;
  padding-bottom: 10px;
}
header img {
  width: 100%;
  max-height: 110px;
  -o-object-fit: contain;
     object-fit: contain;
}

footer {
  border-top: 1px solid #2c357c;
  background-color: #fff !important;
  width: 100vw;
}
footer a {
  color: #2c357c;
}
footer a:hover, footer a:active, footer a.active {
  color: #ee3842 !important;
}

.throbble-wrapper {
  width: 100vw;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  z-index: 999999;
  position: fixed;
}
.throbble-wrapper .throbble-inner {
  width: 100%;
  min-height: 120px;
  padding: 0 20px;
  color: #fff;
  background-color: rgba(44, 53, 124, 0.8);
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
}
.throbble-wrapper .throbble-inner .spinner-block {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 2px 2px 0 2px;
  font-size: 14px;
  margin: 0 auto;
}
.throbble-wrapper .throbble-inner .message-block {
  font-size: 18px;
}
/*# sourceMappingURL=est-africa.css.map */