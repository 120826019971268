header {
    // max-width: 600px;
    height: 120px;
    border-bottom: 1px solid $purple-color;
    background-color: #fff !important;
    opacity: 1;
    padding-bottom: 10px;

    img {
        width: 100%;
        max-height: 110px;
        object-fit: contain;
    }
}

footer {
    border-top: 1px solid $purple-color;
    background-color: #fff !important;
    width: 100vw;
    // max-width: 600px;

    a {
        color: $purple-color;

        &:hover,
        &:active,
        &.active {
            color: $red-color !important;
        }
    }

}